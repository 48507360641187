.text-shadow{
    font-size: 125px;
    color:#ff003d;
    font-style: italic;
    cursor: pointer;
    line-height: 1;
    padding-right: 0.15em;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}
.search-div-2{
    width: 20px;
}
.cursor-pointer-main{
    width:20px;
  }

.text-shadow:hover{
    /* text-decoration: none; */
    color: white;
    -webkit-text-fill-color: white;
    transition: 0.5s;
}
.ham-1{
    flex: 1 1 0;
    margin-top: 1%;
    margin-left: 5%;
}
.ham-main{
    display: flex;
}
.ham-2{
    flex: 1 1 0;
    margin-top:1% ;
    margin-right: 10%;
    justify-content: center;
    /* margin-bottom: 5%; */
}
.ham-style-right{
    margin-left: 15%;
    margin-right: 5%;
    padding-top: 3%;
    border-top: 1px solid white;
}
.ham-style-right-opt{
    font-size: 30px;
    line-height: 1;
    padding-bottom: 10px;
    color: white;
    cursor: pointer;
    text-align: left;
    width: max-content;
}
.ham-footer-main{
    width: 100%;
    position: fixed;
  left: 0;
  bottom: 0;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 2%;
}
.ham-footer-1{
    display: flex;
    justify-content: space-between;
    /* justify-content: end; */
}
.ham-footer-name img{
    width: auto;
    height: 40px;
    margin-bottom: 2%;
}
.ham-footer-logo{
    flex: 1 1 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* align-self: center; */
}
.ham-footer-logo > img{
    width: 3%;
    margin-left: 2%;
    height: 40px;
}
.ham-footer-2{
    display: flex;
    justify-content: space-between;
    /* grid-template-columns: 75% 25%; */
}
.ham-footer-2-1{
    display: flex;
    justify-content: flex-start;
    width: 60%;
}
.ham-footer-2-1-1{
    width: 100%;
}

.ham-footer-2-1-2{
    width: 100%;
}

/* .ham-footer-2-1 > div{
    display: flex;
    flex:1 1 0;
    justify-content: flex-start;
    flex-basis: content;
    color: white;
    cursor: pointer;
    font-size: 20px;

} */
.ham-footer-2-1-1{
    display: flex;

}
.ham-footer-2-1 > div{
    display: flex;
    flex:1 1 0;
    justify-content: flex-start;
    flex-basis: content;
    color: white;
    cursor: pointer;
    font-size: 20px;
    /* width: max-content; */
}

.ham-footer-2-1-1 >div{
    /* flex: 1 1 0; */
    /* width: max-content; */
    margin-right: 4%;
}
.ham-footer-2-1-2 > div{
    /* flex: 1 1 0; */
    margin-right: 4%;
    /* padding-right:2%; */

}
.ham-footer-theest{
    display: flex;
    /* width: 25%; */
    font-size: 20px;
    justify-content: flex-end;
    color: white;
}
@media (min-width: 1441px) and (max-width: 1700px) {
 .text-shadow{
     font-size: 90px;
 }
 .ham-footer-2-1 > div{
    margin-right:2% ;
     font-size: 12px;
 }
 .ham-footer-logo > img{
    height: 30px;
}
 .ham-footer-theest{
    font-size: 16px;
}
}
@media (min-width: 1201px) and (max-width: 1440px){
    .text-shadow{
        font-size: 90px;
    }
    .ham-footer-2-1 > div{
       margin-right:2% ;
       font-size: 12px;
    }
    .ham-footer-logo > img{
       height: 30px;
   }

    .ham-footer-theest{
       font-size: 16px;
   }
}
@media (min-width: 1023px) and (max-width: 1200px){
    .text-shadow{
        font-size: 100px;
    }
    .ham-footer-name{
    font-size: 30px;
        }
    .ham-footer-2-1 > div{
       margin-right:2% ;
        font-size: 12px;
    }
    .ham-footer-logo > img{
       height: 20px;
   }
   .ham-footer-2-1 > div{
    font-size: 10px;
}
    .ham-footer-theest{
       font-size: 14px;
   }
}
@media (min-width: 769px) and (max-width: 1023px){
    .text-shadow{
        font-size: 90px;
    }
    .ham-footer-name{
    font-size: 25px;
    }
    .ham-footer-logo > img{
       height: 15px;
   }
   .ham-footer-2-1 > div{
    font-size: 10px;
}
    .ham-footer-theest{
       font-size: 12px;
   }


}
@media (min-width: 480px) and (max-width: 768px){
    .ham-footer-name img{
        width: auto;
        height: 30px;
        margin-bottom: 2%;
    }
    .text-shadow{
        font-size: 80px;
    }
    .ham-footer-name{
    font-size: 15px;
    }
    .ham-footer-logo > img{
       height: 30px;
   }

    .ham-footer-theest{
       font-size: 10px;
   }
   .ham-2{
       display: none;
   }
   .ham-footer-2-1 {
    flex-direction: column;
}
.ham-footer-2-1-1 > div{
    font-size: 8px;
}

.ham-footer-2-1-2 > div{
    font-size: 8px;
}
}

@media (min-width: 300px) and (max-width: 480px){
    .ham-footer-2{
        display: none;
    }
    .menuCon {
        width: calc(100% - 150px);
        height: 30px;
    }
    .ham-footer-name img{
        width: auto;
        height: 30px;
        margin-bottom: 2%;
    }
    .ham-footer-1{
        display: block;
    }
    .ham-footer-logo{
        justify-content: center;
    }
    .text-shadow{
        font-size: 80px;
    }
    .ham-footer-name{
        text-align: center;
    font-size: 15px;
    }
    .ham-footer-logo > img{
       height: 25px;
       margin-top: 5px;
       margin-bottom: 5px;
       width: 10%;
   }

    .ham-footer-theest{
       font-size: 10px;
   }
   .ham-2{
       display: none;
   }
   .ham-footer-2-1 {
    flex-direction: column;
}
.ham-footer-2-1-1 > div{
    font-size: 8px;
}

.ham-footer-2-1-2 > div{
    font-size: 8px;
}

}


