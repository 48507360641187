.culture-heading {
  text-align: center;
}
.culture-heading img {
  height:200px;
}


.culture-content-main {
  display: grid;
  margin-right: 10%;
  grid-template-columns: 40% 60%;
  /* margin-right: 0.5%; */
  column-gap: 6%;
  row-gap: 3%;
}
.culture-main-row {
  background-color: white;
  z-index: 2;
  padding-right: 5%;
  padding-left: 12%;
}
.culture-content-1 {
  margin-top: 50px;
}
.culture-content-1 img {
  width: 100%;
}
.culture-content-3 img {
  width: 100%;
}
.culture-content-2 {
  width: 100%;
  grid-row: span 2;
  /* grid-column: span 2; */
}

.culture-content-2 img {
  width: 100%;
}
.culture-content-3 {
  width: 70%;
  justify-self: flex-end;
}
.culture-content-cat {
  padding-top: 15px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 100;
  color: #ff003d;
}
.culture-content-title {
  width: 100%;
  padding-top: 15px;
  font-size: 26px;
  line-height: 1.06;
}
.culture-content-title-2{
  width: 100%;
}
.culture-content-rm {
  width: 100%;
}
.culture-content-author {
  padding-top: 15px;
  font-size: 18px;
  line-height: 1;
  text-align: left;
  color: #ff003d;
}
.culture-more-read-main {
  margin-top: 50px;
  padding-left: 15%;
  padding-right:15% ;
  /* padding-right: 30px; */
  border-left: 1px solid grey;
}
.culture-more-read {
  width: max-content;
  font-size: 90px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -2.25px;
  text-align: left;
  color: #ff003d;
  /* border-bottom: 1px solid #ff003d; */
  margin-bottom: 15px;
}
.culture-more-read-1 {
  border-bottom: 1px solid #ff003d;
}

.culture-content-read-more {
  /* width: max-content; */
  width: 100%;
  border-bottom: 1px solid grey;
  letter-spacing: normal;
  padding-bottom: 30px;
}
.culture-content-4 {
  border: none;
}
.culture-content-read-btn {
  margin-top: 20px;
  width: max-content;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ff003d;
  border-bottom: 3px solid #ff003d;
}
.culture-image-grad {
  /* background-image: -webkit-linear-gradient(to top, rgba(255, 0, 61, 0) 95%, rgba(255, 0, 61, 0.01) 72%, rgba(255, 0, 61, 0.04) 58%, rgba(255, 0, 61, 0.1) 47%, rgba(255, 0, 61, 0.18) 37%, rgba(255, 0, 61, 0.28) 28%, rgba(255, 0, 61, 0.41) 20%, rgba(255, 0, 61, 0.56) 12%, rgba(255, 0, 61, 0.73) 4%, rgba(255, 0, 61, 0.93) -3%, #ff003d -5%); */

  background: linear-gradient(
    rgba(255, 0, 61, 0) 5%,
    rgba(255, 0, 61, 0.01) 28%,
    rgba(255, 0, 61, 0.04) 42%,
    rgba(255, 0, 61, 0.1) 53%,
    rgba(255, 0, 61, 0.18) 63%,
    rgba(255, 0, 61, 0.28) 72%,
    rgba(255, 0, 61, 0.41) 76%,
    rgba(255, 0, 61, 0.56) 80%,
    rgba(255, 0, 61, 0.73) 96%,
    rgba(255, 0, 61, 0.93) 103%,
    #ff003d 105%
  );
  width: 100%;
  padding-bottom: 5%;
}
.culture-content-rm-titile{
  color: #383838;
}
.culture-image-section {
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 7%;
  justify-content: center;
}
.culture-image-image {
  width: 60%;
}
.culture-image-image img {
  width: 100%;
}
.culture-image-content {
  width: 40%;
  margin-left: 5%;
  display: flex;
  align-items: center;
  justify-items: center;
}
.culture-image-content-head {
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 0.97;
  letter-spacing: -0.4px;
  text-align: left;
  color: #000;
  margin-bottom: 25px;
}
.culture-image-content-title {
  font-size: 66px;
  font-stretch: normal;
  font-style: normal;
  padding-right:20% ;
  line-height: 1;
  letter-spacing: -0.66px;
  text-align: left;
  color: #000;
  margin-bottom: 25px;
}
.culture-image-content-bottom {
  font-size: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.culture-image-content-bottom p{
  font-family: "Biotif-SemiBold" !important;
}
.culture-image-content-btn {
  margin-top: 25px;
  /* background-color: #000; */
}
.culture-image-content-btn button {
  font-size: 15px;
  padding: 7px 12px;
  background-color: black;
  color: white;
  border: none;
}
.culture-content-read-btn-1{
  font-size: 20px;
}
.culture-pillar-main{
  text-align: center;
  margin-top: 10%;
}
.culture-pillar-img img{
  width: 100%;
}
.culture-pillar-cont{
  border: 1px solid grey;
}
.culture-pillar-open{
  padding:2px 4px;
  width: max-content;
  color:blue;
}
@media (max-width: 480px) {
  .culture-heading img {
    height:38px;
  }
  .culture-content-main {
    grid-template-columns: auto;
    margin-left: 5%;
    margin-right: 5%;
    gap: 0;
  }
  .culture-main-row {
    background-color: white;
    z-index: 2;
    padding-left: 0;
    padding-right:0 ;
  }
  .culture-content {
    margin-bottom: 2%;
  }
  .culture-content-1 {
    width: 100%;
    margin-top: 0px;
    align-self: center;
    justify-self: center;
  }
  .culture-content-1 img {
    width: 100%;
  }
  .culture-content-3 {
    width: 100%;
    justify-self: center;
    align-self: center;
  }
  .culture-content-3 img {
    width: 100%;
  }
  .culture-content-2 {
    width: 100%;
    grid-row: span 1;
    justify-self: center;
    align-self: center;
  }
  .culture-content-2 img {
    width: 100%;
  }
  .culture-content-cat {
    padding-top: 10px;
    font-size: 13px;
  }
  .culture-content-title {
    font-size: 18px;
    line-height:1.3;
    padding-top: 7px;
  }
  .culture-content-author {
    font-size: 13px;
    Padding-top: 7px;
    margin-bottom: 10%;
  }
 .culture-more-read-warp{
   width: 100%;
 }
  .culture-more-read-main {
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-left: 0;
  }
  .culture-more-read {
    width: 100%;
    /* width: max-content; */
    font-size: 70px;
    display: flex;
    justify-content: center;
    border: none;
  }
  .culture-more-read-1 {
    /* width: 100%; */
    width: max-content;
    font-size: 40px;
    text-align: center;
    border-bottom: 1px solid #ff003d;
  }
  .culture-content-read-more {
    width: max-content;
    border-bottom: 1px solid grey;
    letter-spacing: normal;

    margin-left: 0% !important;
    margin-right: 0% !important;
    width: 100% !important;
    padding-bottom: 0;
  }
  .culture-content-read-btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    border: none;
  }
  .culture-content-read-btn-1 {
    width: max-content;
    border-bottom: 1px solid #ff003d;
  }
  .culture-content-6{
      border: none !important;
  }

  .culture-content-6{
      border: none !important;
  }
  .culture-content-rm-cat{
    padding-top: 0%;
  }
  .culture-content-rm-author{
    margin-bottom: 5%;
  }
  .culture-image-section {
    display: block;
    margin-left: 0;
    margin-right: 0%;
    margin-top: 10%;
  }
  .culture-image-image {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .culture-image-image img {
    width: 100%;
  }
  .culture-image-content {
    width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .culture-image-content-head {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  .culture-image-content-title {
    text-align: center;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    font-size: 26px;
    padding-right: 0;
  }
  .culture-image-content-bottom {
    text-align: center;
    margin: 0px 0px 10px 0px;
    font-size: 14px;
  }
  .culture-image-content-btn {
    margin-top: 20px;
  }
  .culture-image-content-btn button {
    font-size: 10px;
    line-height: 1;
    padding: 10px 10px 10px 10px;
    background-color: black;
    color: white;
    border: none;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .culture-heading img {
    height:50px;
  }
  .culture-content-main {
    grid-template-columns: auto;
    margin-left: 15%;
    margin-right: 15%;
    gap: 0;
  }
  .culture-main-row {
    background-color: white;
    z-index: 2;
    padding: 0;
  }
  .culture-content {
    margin-bottom: 0%;
  }
  .culture-content-1 {
    width: 100%;
    margin-top: 0px;
    align-self: center;
    justify-self: center;
  }
  .culture-content-1 img {
    width: 100%;
  }
  .culture-content-3 {
    width: 100%;
    justify-self: center;
    align-self: center;
  }
  .culture-content-3 img {
    width: 100%;
  }
  .culture-content-2 {
    width: 100%;
    grid-row: span 1;
    justify-self: center;
    align-self: center;
  }
  .culture-content-2 img {
    width: 100%;
  }

  .culture-content-cat {
    padding-top: 10px;
    font-size: 12px;
  }
  .culture-content-title {
    width: 100%;
    font-size: 20px;
    padding-top: 7px;
  }
  .culture-content-author {
    font-size: 12px;
    padding-top: 7px;
    margin-bottom: 5%;
  }
 .culture-more-read-warp{
   width: 100%;
 }
  .culture-more-read-main {
    margin-top: 5%;
    margin-right: 9%;
    margin-left: 9%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-left: 0;
  }
  .culture-more-read {
    width: 100%;
    /* width: max-content; */
    font-size: 70px;
    display: flex;
    justify-content: center;
    border: none;
  }
  .culture-more-read-1 {
    /* width: 100%; */
    width: max-content;
    font-size: 60px;
    text-align: center;
    border-bottom: 1px solid #ff003d;
  }
  .culture-content-read-more {
    width: max-content;
    border-bottom: 1px solid grey;
    letter-spacing: normal;
    margin-left: 0% !important;
    margin-right: 0% !important;
    width: 100% !important;
    padding-bottom: 0;
  }
  .culture-content-read-btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    border: none;
  }
  .culture-content-read-btn-1 {
    width: max-content;
    border-bottom: 1px solid #ff003d;
  }
  .culture-content-6{
      border: none !important;
  }

  .culture-content-6{
      border: none !important;
  }
  .culture-image-section {
    display: block;
    margin-left: 0;
    margin-right: 0%;
    margin-top: 10%;
  }
  .culture-image-image {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .culture-image-image img {
    width: 100%;
  }
  .culture-image-content {
    margin-top: 20px;
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .culture-image-content-head {
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
  .culture-image-content-title {
    text-align: center;
    padding-right: 0;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    font-size: 22px;
  }
  .culture-image-content-bottom {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000;
  }
  .culture-image-content-btn {
    margin-top: 20px;
  }
  .culture-image-content-btn button {
    font-size: 15px;
    background-color: black;
    color: white;
    border: none;
  }

}

@media (min-width: 769px) and (max-width: 992px) {
  .culture-heading img {
    height:80px;
  }

  .culture-content-main {
    margin-left: 30px !important;
  }

  .culture-content-1 {
    width: 100% !important;
  }
  .culture-content-1 img {
    width: 100% !important;
  }
  .culture-content-3 {
    width: 80% !important;
  }
  .culture-content-3 img {
    width: 100% !important;
  }
  .culture-content-2 {
    width: 100% !important;
  }
  .culture-content-2 img {
    width: 100% !important;
  }
  .culture-content-cat {
    padding-top: 10px ;
    font-size: 10px ;
  }
  .culture-content-title {
    width: 100% ;
    font-size: 18px ;
    padding-top: 7px ;
  }

  .culture-content-author {
    padding-top: 7px ;
    font-size: 12px !important;
  }
  .culture-content-rm-cat{
    padding-top: 3%;
  }
  .culture-content-rm-author{
    padding-bottom: 3%;
  }

  .culture-more-read-main {
    margin-top: 9%;
    margin-left: 9%;
    margin-right:9% ;

    padding: 0px;
    padding-left: 0px;
    padding-right: 0px;
    border-left: 0;
  }
  .culture-more-read {
    width: 100%;
    margin-top:4% ;
    /* width: max-content; */
    font-size: 70px;
    border: none;
  }
  .culture-more-read-1 {
    /* width: 100%; */
    width: max-content;
    font-size: 60px;
    text-align: center;
    border-bottom: 1px solid #ff003d;
  }
  .culture-content-read-more {

    border-bottom: 1px solid grey;
    letter-spacing: normal;
   padding-bottom: 0;
  }
  .culture-content-read-btn {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    border: none;
  }
  .culture-content-read-btn-1 {
    width: max-content;
    border-bottom: 1px solid #ff003d;
  }
  .culture-content-6{
      border: none !important;
  }

  .culture-image-section {
    display: block;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
  }
  .culture-image-image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .culture-image-image img {
    width: 100%;
  }
  .culture-image-content {
    margin-top: 20px;
    margin-left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .culture-image-content-head {
    font-size: 25px;
    margin-bottom: 20px;
    text-align: center;
  }
  .culture-image-content-title {
    text-align: center;
    width: 100%;
    padding-right: 0;
    height: auto;
    margin-bottom: 20px;
    font-size: 30px;
  }
  .culture-image-content-bottom {
    font-size: 18px;
    text-align: center;
  }
  .culture-image-content-btn {
    margin-top: 20px;
    text-align: center;
  }
  .culture-image-content-btn button {
    font-size: 10px;
    padding: 7px 12px;
    background-color: black;
    color: white;
    border: none;
  }

}
@media (min-width: 993px) and (max-width: 1200px) {
  .culture-heading img {
    height:100px;
  }

  .culture-content-main {
    margin-left: 30px !important;
  }

  .culture-content-1 {
    width: 100% !important;
  }
  .culture-content-1 img {
    width: 100% !important;
  }
  .culture-content-3 {
    width: 80% !important;
  }
  .culture-content-3 img {
    width: 100% !important;
  }
  .culture-content-2 {
    width: 100% !important;
  }
  .culture-content-2 img {
    width: 100% !important;
  }
  .culture-content-cat {
    padding-top: 10px;
    font-size: 12px !important;
  }
  .culture-content-title {
    width: 100% !important;
    font-size: 18px !important;
    padding-top: 7px;
  }
  .culture-content-read-more{
    width: 180px !important;
  }
  .culture-content-author {
    font-size: 12px !important;
    padding-top: 7px;
  }
  .culture-more-read-main {
    padding-left: 15%;
  padding-right:15% ;
  }
  .culture-more-read {
    width: max-content !important;
    font-size: 40px !important;
  }

  .culture-content-read-btn {
    margin-top: 20px !important;
  }
  .culture-image-section {
    display: flex !important;
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-top: 5% !important;
  }
  .culture-image-content {
    margin-left: 50px !important;
  }
  .culture-image-content-head {
    font-size: 32px !important;
    margin-bottom: 20px !important;
  }
  .culture-image-content-title {
    margin-bottom: 20px !important;
    font-size: 35px !important;
  }
  .culture-image-content-bottom {
    font-size: 16px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.07 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #000 !important;
  }
  .culture-image-content-btn {
    margin-top: 20px !important;
  }
  .culture-image-content-btn button {
    font-size: 15px !important;
    background-color: black !important;
    color: white !important;
    border: none !important;
  }
}
@media (min-width: 1201px) and (max-width: 1440px) {
  .culture-heading img {
    height:120px;
  }

  .culture-content-main {
    margin-right: 10%;
    gap: 5%;
  }
  .culture-main-row {
    background-color: white;
    z-index: 2;
  }
  .culture-content-1 {
    margin-top: 50px;
  }
  .culture-content-cat {
    padding-top: 10px;
    font-size: 14px;
  }
  .culture-content-title {
    padding-top: 10px;
    font-size: 20px;
  }
  .culture-content-author {
    padding-top: 10px;
    font-size: 15px;
  }
  .culture-more-read-main {
    padding-left: 15%;
  padding-right:15% ;
  }
  .culture-more-read {
    width: 100%;
    border: none;
    /* width: max-content; */
  }
  .culture-more-read-1 {
    width: max-content;
    font-size: 50px;
    border-bottom: 1px solid #ff003d;
  }
  .culture-image-section {
    display: flex;
    margin-left: 5%;
    margin-right: 0%;
    margin-top: 5%;
  }

  .culture-image-content {
    margin-left: 50px;
  }
  .culture-image-content-head {
    font-size: 35px;
    margin-bottom: 30px;
  }
  .culture-image-content-title {
    margin-bottom: 20px;
    font-size: 50px;
  }
  .culture-image-content-bottom {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .culture-image-content-btn {
    margin-top: 25px;
    /* background-color: #000; */
  }
  .culture-image-content-btn button {
    font-size: 22px;
    background-color: black;
    color: white;
    border: none;
  }
  .culture-image-section {
    display: flex !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    margin-top: 5% !important;
  }

  .culture-image-content {
    margin-left: 50px !important;
  }
  .culture-image-content-head {
    font-size: 32px !important;
    margin-bottom: 25px !important;
  }
  .culture-image-content-title {
    margin-bottom: 25px !important;
    font-size: 35px !important;
  }
  .culture-image-content-btn {
    margin-top: 25px !important;
  }
  .culture-image-content-btn button {
    font-size: 14px !important;
    background-color: black !important;
    color: white !important;
    border: none !important;
  }
}
@media (min-width: 1441px) and (max-width: 1750px) {
  .culture-heading img {
    height:150px;
  }

  .culture-content-2{
    width: 100%;
  }
  .culture-content-2 img{
    width: 100%;
  }
  .culture-image-section{
      display: flex;
  }
  .culture-image-content-head{
    font-size: 30px;
  }
  .culture-more-read {
    font-size: 70px;
  }
  .culture-content-cat {
    font-size: 14px;
  }
  .culture-content-title {
    font-size: 23px;
  }
  .culture-content-author {
    font-size: 18px;
  }
  .culture-image-content-title {
    font-size: 50px !important;
  }
  .culture-image-content-bottom{
    font-size: 20px !important;
  }
}