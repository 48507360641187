.subscribe-btn {
    font-size: 16px;
    background: #ff003d;
    color: #fff;
    padding: 10px 20px 10px 20px;
    border: 1px solid #ff003d;
    position: fixed;
    z-index: 100;
    bottom: 5%;
    right: 3%;
    font-family: "Biotif-bold" !important;
  }

  .subscribe-btn.fixed {
    bottom: 35%; /* Adjust the position as needed */
  }

  @media (max-width: 480px) {
    .subscribe-btn {
        font-size: 10px;
        padding: 7px 12px;
      }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .subscribe-btn {
        font-size: 10px;
        padding: 7px 12px;
      }
  }
  @media (min-width: 769px) and (max-width: 1023px) {
    .subscribe-btn {
        font-size: 10px;
        padding: 7px 12px;
      }
  }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .subscribe-btn {
        font-size: 12px;
        padding: 7px 12px;
      }
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    .subscribe-btn {
        font-size: 15px;
        padding: 7px 12px;
      }
  }
  @media (min-width: 1441px) and (max-width: 1750px) {
    .subscribe-btn {
        font-size: 15px;
        padding: 7px 12px;
      }
  }