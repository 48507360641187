.sd-one-story-only{
    margin-left: 30%;
    margin-right: 30%;
}

@media(min-width:769px){
.section-story-display-img img{
    height: calc(calc(70vw - 60px)/3);
    object-fit: cover;
}

}
.mob-view-ad-img{
    display: none;
}

@media (min-width:481px) and (max-width:768px){
    .sd-one-story-only{
        margin-left: 20%;
        margin-right: 20%;
    }
}

@media(max-width:480px){
    .mob-view-ad-img{
        display: block;
    }
    .web-view-ad-img{
        display: none;
    }
    .sd-one-story-only{
        margin-left: 5%;
        margin-right: 5%;
    }

}


