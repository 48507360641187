.common-section{
    height: auto;
}
.common-partone{
    position: absolute;
    width: 100%;
    height: 500px;
    background-color: #ff003d;
    display: flex;
    justify-content: center;
}
.common-header-name{
 padding-top: 2%;
 padding-bottom: 1%;
}

.common-header-name img{
    width: 900px;
    /* color: white; */
}
.common-parttwo{
    margin-left: 7%;
    margin-right: 7%;
}
.common-main-div{
    display: flex;
    flex: 1 1 0;
    gap: 3.5%;
    justify-content: space-between;
    position: relative;
    top: 16vh;
    z-index: 0;
    margin-bottom: 18%;
}
.common-main-1{
    flex: 1 1 0;
    justify-content: center;
    z-index: 1;
}
.common-main-1 img{
    /* margin-top: 10%; */
    /* width: 500px; */
    width: 100%;
    /* height: 500px; */
    object-fit: cover;
}
.common-main-2{
    flex: 1 1 0;
    justify-content: center;
    z-index: 1;
}
.common-main-2 img{
    width: 100%;
    /* height: 600px; */
    object-fit: cover;
}
.common-main-3{
    flex: 1 1 0;
    justify-content: center;
    z-index: 1;
}
.common-main-3 img{
    /* margin-top: 15%; */
    width: 100%;
    /* height: 400px; */
    object-fit: cover;
}
.common-main-1-text{
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    text-align: center;
    margin-top: 12px;
    font-size: 40px;
    line-height: 0.85;
    letter-spacing: -10;
}
.common-category{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    /* padding: 13px; */
    width: max-content;
    padding: 4px 13px 2px 13px;
    /* padding-right: 13px; */
    font-size: 14px;
    /* line-height: 1; */
    height: 22px;
    letter-spacing: 100;
    /* width: auto; */
    /* display: inline; */
    border: 1px solid #ff003d;
    background-color: white;
    margin-left: 15%;
    margin-top: -12px;
    color:#ff003d ;
    border-radius: 30px;
}
.common-author{
    font-size: 20px;
    text-align: end;
    margin-top: 20px;
    line-height: 1;
    letter-spacing: 0;
    margin-right: 12%;
    color: #ff003d;
}
@media (max-width: 480px){
    .common-section{
        height: auto;
        margin-bottom: 50px;
    }
    .common-partone{
        position: static;
        width: 100%;
        height: 0px;
        background-color: #ff003d;
        display: flex;
        justify-content: center;
    }
    .common-header-name img{
            width: 200px;
    }
    .common-parttwo{
        margin-left: 5%;
        margin-right: 5%;
    }
    .common-main-div{
        display: block;
        flex: 1 1 0;
        gap: 0%;
        position: static;
        margin-left: 0%;
        margin-right: 0%;
        top: 0;
        /* margin-top: 20px; */
    }
    .common-category{
        font-size: 12px;
        /* line-height: 20px; */
        margin-top: -10px;
    }

    .common-main-1{
        flex: 1 1 0;
        margin-top: 25%;
        justify-content: center;
        z-index: 1;
        margin-bottom: 15%;
    }
    .common-main-1 img{
        margin-top: 0%;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .common-main-2{
        margin-top: 10%;
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
        margin-bottom: 15%;
    }
    .common-main-2 img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .common-main-3{
        margin-top: 10%;
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
        margin-bottom: 3%;
    }
    .common-main-3 img{
        margin-top: 0%;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .common-main-1-text{
        display: block;
        justify-content: center;
        align-items: center;
        font-size: 24px;
    }
    .common-category{
        font-size: 10px;
        /* line-height: 18px; */
    }
    .common-author{
        margin-top: 10px;
        font-size: 13px;
    }

}
@media (min-width: 481px) and (max-width: 768px){
    .common-section{
        height: auto;
        margin-bottom: 50px;
    }
    .common-partone{
        position: static;
        width: 100%;
        height: 0px;
        background-color: #ff003d;
        display: flex;
        justify-content: center;
    }
    .common-header-name img{
        width: 300px;
}

    .common-parttwo{
        margin-left: 15%;
        margin-right: 15%;
    }
    .common-main-div{
        display: block;
        flex: 1 1 0;
        gap: 0%;
        position: static;
        margin-left: 0%;
        margin-right: 0%;
        top: 0;
    }

    .common-main-1{
        flex: 1 1 0;
        margin-top: 5%;
        justify-content: center;
        z-index: 1;
        margin-bottom: 5%;
    }
    .common-main-1 img{
        margin-top: 0%;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .common-main-2{
        margin-top: 5%;
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
        margin-bottom: 5%;
    }
    .common-main-2 img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .common-main-3{
        margin-top: 5%;
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
        margin-bottom: 5%;
    }
    .common-main-3 img{
        margin-top: 0%;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .common-main-1-text{
        display: block;
        font-size: 25px;
    }
    .common-category{
        font-size: 14px;
        /* line-height: 22px; */
    }
    .common-author{
        margin-top: 10px;
        font-size: 16px;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .common-section{
        height: auto;
        margin-bottom: 125px;
    }
    .common-partone{
        position: absolute;
        width: 100%;
        /* height: 32vh; */
        height: 280px;
        background-color: #ff003d;
        display: flex;
        justify-content: center;
    }
    .common-header-name img{
        width: 400px;
    }
    .common-parttwo{
        margin-left: 5%;
        margin-right: 5%;
    }
    .common-main-div{
        display: flex;
        flex: 1 1 0;
        gap: 3.5%;
        justify-content: space-between;
        position: relative;
        top: 100px;
    }

    .common-main-1{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-1 img{
        /* margin-top: 10%; */
        width: 100%;
        /* height: 240px; */
        object-fit: cover;
    }
    .common-main-2{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-2 img{
        width: 100%;
        /* height: 400px; */
        object-fit: cover;
    }
    .common-main-3{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-3 img{
        /* margin-top: 15%; */
        width: 100%;
        /* height: 220px; */
        object-fit: cover;
    }
    .common-main-1-text{
        font-size: 25px;
    }
    .common-category{
        font-size: 10px;
        /* line-height: 18px; */
        margin-left: 15%;
    }
    .common-author{
        margin-top: 10px;
        font-size: 12px;
    }
}
@media (min-width: 993px) and (max-width: 1200px) {
    .common-section{
        height: auto;
        margin-bottom: 125px;
    }
    .common-partone{
        position: absolute;
        width: 100%;
        height: 320px;
        background-color: #ff003d;
        display: flex;
        justify-content: center;
    }
    .common-header-name img{
        width: 500px;
    }
    .common-parttwo{
        margin-left: 5%;
        margin-right: 5%;
    }
    .common-main-div{
        display: flex;
        flex: 1 1 0;
        gap: 3.5%;
        justify-content: space-between;
        position: relative;
        top: 150px;
        margin-bottom: 190px;
    }

    .common-main-1{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-1 img{
        /* margin-top: 10%; */
        width: 100%;
        /* height: 240px; */
        object-fit: cover;
    }
    .common-main-2{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-2 img{
        width: 100%;
        /* height: 400px; */
        object-fit: cover;
    }
    .common-main-3{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-3 img{
        /* margin-top: 15%; */
        width: 100%;
        /* height: 220px; */
        object-fit: cover;
    }
    .common-main-1-text{
        font-size: 25px;
    }
    .common-category{
        font-size: 10px;
        /* line-height: 18px; */
    }

    .common-author{
        margin-top: 10px;
        font-size: 14px;
    }
}
@media (min-width: 1201px) and (max-width: 1440px) {
    .common-section{
        height: auto;
        margin-bottom: 12.5%;
    }
    .common-header-name img{
        width: 500px;
        /* color: white; */
    }
    .common-partone{
        position: absolute;
        width: 100%;
        height: 380px;
        background-color: #ff003d;
        display: flex;
        justify-content: center;
    }
    .common-parttwo{
        margin-left: 7%;
        margin-right: 7%;
    }
    .common-main-div{
        display: flex;
        flex: 1 1 0;
        gap: 3.5%;
        justify-content: space-between;
        position: relative;
        top: 150px;
    }
    .common-main-1{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-1 img{
        /* margin-top: 10%; */
        width: 100%;
        /* height: 240px; */
        object-fit: cover;
    }
    .common-main-2{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-2 img{
        width: 100%;
        /* height: 400px; */
        object-fit: cover;
    }
    .common-main-3{
        flex: 1 1 0;
        justify-content: center;
        z-index: 1;
    }
    .common-main-3 img{
        /* margin-top: 15%; */
        width: 100%;
        /* height: 220px; */
        object-fit: cover;
    }
    .common-main-1-text{
        font-size: 30px;
    }
    .common-category{
        font-size: 14px;
        /* line-height: 22px; */
    }
    .common-author{
        margin-top: 10px;
        font-size: 16px;
    }

}
@media (min-width: 1441px) and (max-width: 1700px) {
    .common-partone{
        height: 425px;
    }
    .common-header-name{
        padding-top: 2%;
        padding-bottom: 1%;
       }

       .common-header-name img{
           width: 700px;
           /* color: white; */
       }
       .common-category{
        margin-left: 15%;
    }
    .common-section{
        height: auto;
        margin-bottom: 12.5%;
    }
    .common-main-div{
        top: 150px;
    }
    .common-main-1-text{
        font-size: 35px;
    }
    .common-category{
        font-size: 12px;
        /* line-height: 20px; */
    }

    .common-author{
        margin-top: 10px;
        font-size: 16px;
    }
}
