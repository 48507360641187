.news-letter-subs-main {
    /* margin-top: 10%; */
    /* height: 73vh; */
    /* position: absolute; */
    /* top: 20%; */
    /* left: 10%; */
    background-color: white;
    padding: 8% 4%;
    border: 10px solid #FF003D;
}

.newsletter-main-img {
    width: 100%;
    /* position: relative; */
}

.newsletter-main-img>img {
    width: 100%;
    /* height: 150vh; */
    object-fit: cover;
}

.newsletter-main-div {
    /* position: relative; */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120vh;
    background-image: url('../../assets/images/newsletter_gif.gif');
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
}

.subscribe-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 5%; */
}

.subscribe-txt-1 {
    text-align: center;
    font-size: 60px;
    line-height: 1.2;
    padding-bottom: 5%;
    /* font-weight: 700; */
}

.subscribe-txt-2 {
    text-align: center;
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 1%;
    margin-bottom: 5%;
}

.subscribe-input-1 {
    width: auto;
    display: flex;
}

.subscribe-input-1 input {
    border: solid 2px #58595b;
    width: 300px;
    height: 60px;
    font-size: 30px;
    margin: 5px 2px;
    padding: 2% 4%;
}

.subscribe-input-1 input::placeholder {
    color: #58595b;
}

.subscribe-input-2 input::placeholder {
    color: #58595b;
}

.subscribe-input-2 {
    width: auto;
    display: flex;
}

.subscribe-input-2 input {
    width: 100%;
    height: 60px;
    margin: 5px 2px;
    padding: 2% 2%;
    font-size: 30px;
    border: solid 2px #58595b;
}

.subscribe-btn-margin {
    margin-top: 28px;
}

.subscribe-input-2 button {
    width: 150px;
    height: 60px;
    border: none;
    font-size: 22px;
    padding: 1%;
    margin-block: 4px;
    color: white;
    background-color: #ff003d;
}

@media (max-width: 575px) {

    .subscribe-txt-1 {
        font-size: 25px;
        line-height: 1.2;
    }

    .subscribe-txt-2 {
        font-size: 16px;
        margin-bottom: 2%;
    }

    .subscribe-input-1 {
        display: block;
    }

    .subscribe-input-1 input {
        width: 250px;
        height: 40px;
        font-size: 15px;
        padding: 2% 4%;
    }

    .subscribe-input-2 {
        display: flex;
    }

    .subscribe-input-2 input {
        width: 100%;
        height: 40px;
        margin: 5px 2px;
        padding: 2% 4%;
        font-size: 15px;
    }

    .subscribe-input-2 button {
        width: 75px;
        height: 40px;
        font-size: 12px;
    }

    .subscribe-btn-margin {
        margin-top: 16px;
    }
}

@media (min-width: 576px) and (max-width: 768px) {

    .subscribe-txt-1 {
        font-size: 25px;
        line-height: 1.2;
    }

    .subscribe-txt-2 {
        font-size: 16px;
        margin-bottom: 3%;
    }

    .subscribe-input-1 input {
        width: 150px;
        height: 35px;
        padding: 2% 4%;
        font-size: 14px;
    }

    .subscribe-input-2 {
        display: flex;
    }

    .subscribe-input-2 input {
        width: 100%;
        height: 35px;
        margin: 5px 2px;
        padding: 2% 4%;
        font-size: 14px;
    }

    .subscribe-input-2 button {
        width: 90px;
        height: 35px;
        font-size: 12px;
    }

    .subscribe-btn-margin {
        margin-top: 16px;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
    .subscribe-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 5%; */
    }

    .subscribe-txt-1 {
        font-size: 25px;
        line-height: 1.2;
    }

    .subscribe-txt-2 {
        font-size: 16px;
        margin-bottom: 3%;
    }

    .subscribe-input-1 {
        display: flex;
    }

    .subscribe-input-1 input {
        width: 190px;
        height: 40px;
        font-size: 14px;
        padding: 2% 4%;
    }

    .subscribe-input-2 {
        display: flex;
    }

    .subscribe-input-2 input {
        width: 100%;
        height: 40px;
        margin: 5px 2px;
        padding: 2% 2%;
        font-size: 14px;
    }

    .subscribe-input-2 button {
        width: 100px;
        height: 40px;
        font-size: 14px;
    }
}

@media (min-width: 993px) and (max-width: 1200px) {
    .subscribe-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 5%; */
    }

    .subscribe-txt-1 {
        font-size: 30px;
        line-height: 1.2;
    }

    .subscribe-txt-2 {
        font-size: 16px;
        margin-bottom: 5%;
    }

    .subscribe-input-1 {
        display: flex;
    }

    .subscribe-input-1 input {
        width: 190px;
        height: 40px;
        font-size: 14px;
        padding: 2% 4%;
    }

    .subscribe-input-2 {
        display: flex;
    }

    .subscribe-input-2 input {
        width: 100%;
        height: 40px;
        margin: 5px 2px;
        padding: 2% 2%;
        font-size: 14px;
    }

    .subscribe-input-2 button {
        width: 100px;
        height: 40px;
        font-size: 12px;
    }
}

@media (min-width: 1201px) and (max-width: 1440px) {
    .subscribe-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 5%; */
    }

    .subscribe-txt-1 {
        font-size: 56px;
        line-height: 1.2;
    }

    .subscribe-txt-2 {
        font-size: 20px;
        margin-bottom: 5%;
    }

    .subscribe-input-1 {
        display: flex;
    }

    .subscribe-input-1 input {
        width: 200px;
        height: 40px;
        font-size: 14px;
        padding: 2% 4%;
    }

    .subscribe-input-2 {
        display: flex;
    }

    .subscribe-input-2 input {
        width: 100%;
        height: 40px;
        margin: 5px 2px;
        padding: 2% 2%;
        font-size: 14px;
    }

    .subscribe-input-2 button {
        width: 125px;
        height: 40px;
        font-size: 14px;
    }
}

@media (min-width: 1441px) and (max-width: 1750px) {
    .subscribe-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 5%; */
    }

    .subscribe-txt-1 {
        font-size: 56px;
        line-height: 1.2;
    }

    .subscribe-txt-2 {
        font-size: 20px;
        margin-bottom: 5%;
    }

    .subscribe-input-1 {
        display: flex;
    }

    .subscribe-input-1 input {
        width: 225px;
        height: 50px;
        font-size: 20px;
        padding: 2% 4%;
    }

    .subscribe-input-2 {
        display: flex;
    }

    .subscribe-input-2 input {
        width: 100%;
        height: 50px;
        margin: 5px 2px;
        padding: 2% 2%;
        font-size: 20px;
    }

    .subscribe-input-2 button {
        width: 150px;
        height: 50px;
        font-size: 20px;
    }
}

@media (max-width: 575px) {
    .newsletter-main-img>img {
        height: 100vh;
    }

    .news-letter-subs-main {
        margin-left: 0%;
        margin-right: 0%;
    }
}